import { getHttp, postHttp } from './fetchApi';
import store from '@/store'

export const Studio = {
    URL: 'studio.json',

    get(){
        return getHttp(this.URL)
    },

}
