<template>
  <div class="home">
  </div>
</template>

<script>

    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    name: 'Home',
    // components: {
    //     Carousel,
    // },
    data() {
        return {
            showNavi: false
        }
    },
    mounted(){
        window.$( "#app" ).addClass( "isHome" );

        setTimeout(
            function(){
                window.$( "#parameter-logo" ).addClass( "isAnimating" );
            },
            1000
        )

        var _this = this;
        setTimeout(
            function(){
                // console.log(_this.$store.getters['projects/get'][0].slug);
                // window.location.href = '/project/' + _this.$store.getters['projects/get'][0].slug;
                _this.$router.push({ name: 'Project', params: { slug: _this.$store.getters['projects/get'][0].slug, transformationDirection: 'up' } });
            },
            2000
        )
    },
    unmounted(){
        window.$( "#app" ).removeClass( "isHome" );
        window.$( "#parameter-logo" ).removeClass( "isAnimating" );
    }
}
</script>

<style lang="scss">

</style>
