import { Projects } from '@/http/Projects';

export const projects = {
    namespaced: true,
    state: {
        data: [],
        isMoving: false,
        mobileArrows: true,
    },
    getters: {
        get: state => {
            return state.data
        },
        getProjectBySlug: (state) => (slug) => {
            var projects = state.data.filter( project => (project.slug == slug) );
            return projects.length ? projects[0] : null;
        },
        getPositionProjectBySlug: (state) => (slug) => {
            return state.data.findIndex( project => (project.slug == slug) );
        },
        getNextProjectSlug: (state) => (currentSlug) => {
            var currentProjectIndex = state.data.findIndex((element) => {
                return element.slug == currentSlug;
            });
            console.log(currentProjectIndex);
            if (currentProjectIndex >= 0 && currentProjectIndex < (state.data.length - 1)) {
                return state.data[currentProjectIndex + 1].slug;
            } else {
                return state.data[0].slug;
            }
        },
        getPrevProjectSlug: (state) => (currentSlug) => {
            var currentProjectIndex = state.data.findIndex((element) => {
                return element.slug == currentSlug;
            });
            console.log(currentProjectIndex);
            if (currentProjectIndex > 0) {
                return state.data[currentProjectIndex - 1].slug;
            } else {
                return state.data[state.data.length - 1].slug;
            }
        },
    },
    mutations: {
        set: (state, projects) => {
            state.data = projects;
        },
    },
    actions: {
        async fetch({ commit }){
            const { data } = await Projects.get();
            commit('set', data.data);
        },
    },
}
