<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-auto">
                      <!-- <div class="parameter"></div> -->
                      <img src="@/assets/images/parameter-logo.svg" alt="parameter logo" class="parameter" id="parameter-logo"/>
                  </div>
                  <div class="col-auto header-title">
                      <h1 class="modal-title"><slot name="header"></slot></h1>
                  </div>
                  <div class="col-auto">
                      <button
                        type="button"
                        class="btn-close"
                        @click="close"
                      >
                        <svg class="d-none d-md-block" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L21 21M21 1L1 21" stroke="black" stroke-width="2"/>
                        </svg>
                        <svg class="d-block d-md-none" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.00006L15 15.0001" stroke="black"/>
                            <path d="M1 15.0001L15 1.00006" stroke="black"/>
                        </svg>

                    </button>
                  </div>
              </div>
          </div>
      </header>

      <section class="modal-body">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <slot name="body"></slot>
                  </div>
              </div>
          </div>

       </section>

      <footer class="modal-footer">
        <slot name="footer"></slot>
        <!-- <button
          type="button"
          class="btn-green"
          @click="close"
        >
          Close Modal
        </button> -->
      </footer>
    </div>
  </div>
</template>
