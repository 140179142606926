import { createStore } from 'vuex'

import { projects } from './projects';
import { studio } from './studio';

export default createStore({
  modules: {
      projects: projects,
      studio: studio
  }
})
