import axios from 'axios';

// const BASE_URL = `http://cms.parameter.test`;
const BASE_URL = process.env.VUE_APP_API_BASE_URL+'/';

export default async (url, method, options = {}) => axios ({
    method: method.toUpperCase(),
    url: BASE_URL + url,
    ...options,
});
