<template>
  <div id="nav">
      <div class="container-fluid">
          <div class="row d-flex justify-content-between">
              <div class="col-auto">
                  <router-link to="/" class="style-none parameter-link">
                      <!-- <div class="parameter" ></div> -->
                      <!-- <h1 class="parameter">Parameter</h1> -->
                      <img src="@/assets/images/parameter-logo.svg" alt="parameter logo" class="parameter" id="parameter-logo"/>
                  </router-link>

              </div>
              <div class="col-auto studio-link">
                  <button
                      type="button"
                      class="btn"
                      @click="showModal"
                    >
                     <p>Studio</p>
                 </button>
              </div>
          </div>
      </div>
  </div>


    <router-view v-slot="{ Component }" :isStudioModalVisible="this.isStudioModalVisible">
        <div class="container-projects">
            <transition :name="$route.params.transformationDirection ? 'slide-' + $route.params.transformationDirection : false">
                <component :is="Component" :key="$route.fullPath" />
            </transition>
        </div>
    </router-view>



  <Modal
      v-show="isStudioModalVisible"
      @close="closeModal"
      >
      <template v-slot:header>
          Studio
      </template>
      <template v-slot:body>
          <studio :studioEntry="this.$store.getters['studio/get'].blocks"/>
      </template>
      <template v-slot:footer></template>
    </Modal>

</template>
<script>
import Modal from './components/Modal.vue';
import Studio from './components/Studio.vue';

export default {
    name: 'App',
    components: {
        Modal,
        Studio,
    },
    props: {
        // showNavi: Boolean,
    },
    data() {
      return {
          isStudioModalVisible: false,
      };
    },
    mounted(){
        this.$store.dispatch('projects/fetch');
        this.$store.dispatch('studio/fetch');
    },
    methods: {
        showModal() {
            console.log("asdfhk");
            this.isStudioModalVisible = true;
            window.$( "#app" ).addClass( "modalIsOpen" );
        },
        closeModal() {
            this.isStudioModalVisible = false;
            window.$( "#app" ).removeClass( "modalIsOpen" );
        },
        accordion(item){
            item['is-open'] = item['is-open'] ? false : true;
            console.log(item['is-open']);
        },
    }
}
</script>

<style lang="scss">
    @import "./assets/sass/app.scss";
</style>
