import { Studio } from '@/http/Studio';

export const studio = {
    namespaced: true,
    state: {
        data: {},
    },
    getters: {
        get: state => {
            return state.data
        },
    },
    mutations: {
        set: (state, studio) => {
            state.data = studio;
        },
    },
    actions: {
        async fetch({ commit }){
            const { data } = await Studio.get();
            commit('set', data);
        },
    },
}
